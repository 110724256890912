<template>
  <div class="subscription">
    <Header />
    <HeaderBottom />
    <b-container class="subscription-container">
      <b-row class="justify-content-md-start back-nav">
        <div class="col-lg-12 col-md-6">
          <b-link to="/orders"
            ><b-icon class="mx-2" icon="chevron-left"></b-icon
            ><span>Kembali</span></b-link
          >
        </div>
      </b-row>

      <b-row class="justify-content-md-center p-4">
        <b-card class="subscription-checkout">
          <b-form>
            <div class="review-title">
              <b-row class="justify-content-center my-2">
                <h4>Pilih Metode Pembayaran</h4>
              </b-row>
            </div>

            <div class="payment-gateway">
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <h4>Transfer Virtual Account</h4>
                  <p>
                    Verifikasi otomatis, tidak perlu upload bukti pembayaran.
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <div class="payment-gateway-bank">
                    <b-link :href="this.invoiceUrl">
                      <div class="card">
                        <div class="card-header">
                          <div class="row">
                            <div class="col-10 col-lg-11 my-auto">
                              <p class="mb-0">Tersedia berbagai pilihan bank</p>
                            </div>
                            <div class="col-1 my-auto">
                              <div class="text-right">
                                <b-icon
                                  style="color: #6b7280"
                                  icon="chevron-right"
                                ></b-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="d-none d-lg-block">
                            <img
                              src="@/assets/img/pg/bca.png"
                              alt="BCA"
                              class="mb-2"
                            />
                            <img
                              src="@/assets/img/pg/mandiri.png"
                              alt="Mandiri"
                              class="mx-2 mb-1"
                            />
                            <img
                              src="@/assets/img/pg/bri.png"
                              alt="BRI"
                              class="mx-1 mb-1"
                            />
                            <img
                              src="@/assets/img/pg/bni.png"
                              alt="BNI"
                              class="mx-1 mb-1"
                            />
                            <img
                              src="@/assets/img/pg/permata.png"
                              alt="Permata"
                              class="mx-1 mb-1"
                            />
                            <img
                              src="@/assets/img/pg/bsi.png"
                              alt="BSI"
                              class="mx-1 mb-1"
                            />
                            <img
                              src="@/assets/img/pg/bjb.png"
                              alt="BJB"
                              class="mb-2"
                            />
                          </div>
                          <div class="d-block d-lg-none">
                            <div class="text-center">
                              <img
                                src="@/assets/img/pg/bca.png"
                                alt="BCA"
                                height="16"
                                class="mb-2"
                              />
                              <img
                                src="@/assets/img/pg/mandiri.png"
                                alt="Mandiri"
                                height="16"
                                class="mx-2 mb-1"
                              />
                              <img
                                src="@/assets/img/pg/bri.png"
                                alt="BRI"
                                height="16"
                                class="mx-1 mb-1"
                              />
                              <img
                                src="@/assets/img/pg/bni.png"
                                alt="BNI"
                                height="16"
                                class="mx-1 mb-1"
                              />
                            </div>
                            <div class="text-center">
                              <img
                                src="@/assets/img/pg/permata.png"
                                alt="Permata"
                                height="16"
                                class="mx-1 mb-1"
                              />
                              <img
                                src="@/assets/img/pg/bsi.png"
                                alt="BSI"
                                height="16"
                                class="mx-1 mb-1"
                              />
                              <img
                                src="@/assets/img/pg/bjb.png"
                                alt="BJB"
                                height="16"
                                class="mb-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-link>
                  </div>
                </div>
              </b-row>
            </div>

            <div class="bank-transfer">
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <h4>Transfer Bank Manual</h4>
                  <p>Verifikasi dengan upload bukti pembayaran.</p>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <div class="manual-bank-transfer">
                    <b-link
                      :to="
                        `/order/subscription-checkout/` +
                        this.code +
                        '/' +
                        this.fund_code
                      "
                    >
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-10 col-lg-11 my-auto">
                              <p class="bank-account mb-0">
                                <img
                                  :src="`${imageCore}/${activities_subcription.product.rekening_product.bank.logo}`"
                                  max-height="26"
                                  max-width="86"
                                  alt="Bank Image"
                                />
                                <span class="mx-2">
                                  {{
                                    activities_subcription.product
                                      .rekening_product != null
                                      ? activities_subcription.product
                                          .rekening_product.bank.bank_name
                                      : "-"
                                  }}</span
                                >
                              </p>
                            </div>

                            <div class="col-1 my-auto">
                              <div class="text-right">
                                <b-icon
                                  style="color: #6b7280"
                                  icon="chevron-right"
                                ></b-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-link>
                  </div>
                </div>
              </b-row>
            </div>
          </b-form>
        </b-card>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import instance from "@/utils/api";

export default {
  name: "SubscriptionChoosePaymentMethod",
  metaInfo: {
    title: "Pilih Metode Pembayaran | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    Footer,
  },
  data() {
    return {
      activities_subcription: {},
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      user: "auth/user",
      app_url: "app_url",
      access_token: "auth/access_token",
    }),
  },

  created() {
    this.fund_code = this.$route.params.fund_code;
    this.code = this.$route.params.code;
    this.fetchActivitiesSubcription();
    this.invoiceUrl = localStorage.getItem("invoiceUrl");
  },

  mounted() {},
  methods: {
    async fetchActivitiesSubcription() {
      if (this.user.institutional != null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}subscription-get?code_buy=${this.code}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        if (!response.data.data) {
          return this.$router.push("/NOT-FOUND");
        }
        this.activities_subcription = response.data.data;
      } else {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}subscription-get?code_buy=${this.code}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        if (!response.data.data) {
          return this.$router.push("/NOT-FOUND");
        }
        this.activities_subcription = response.data.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  background: #f3f4f6;
}
.subscription-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.subscription-checkout {
  padding: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

a {
  text-decoration: none;
}

.back-nav {
  padding: 0px 260px 0px 248px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.review-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}

.subscription-checkout {
  width: 600px;
}

hr {
  margin: 0 !important;
}

.bank-transfer {
  margin-top: 40px;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6b7280;
  }

  .manual-bank-transfer {
    .bank-account {
      font-style: normal;
      font-weight: 700;
      color: #1f2937;
    }
  }
}

.payment-gateway {
  margin-top: 40px;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6b7280;
  }
  .payment-gateway-bank {
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #1f2937;
    }
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px !important;
  }
  h1 {
    font-size: 24px !important;
  }
  h4,
  h3,
  span {
    font-size: 16px !important;
  }
  .back-nav {
    padding: 0px;
  }
}
</style>
